import { Icon, InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";
import { twMerge } from "tailwind-merge";

const StyledFormikField = (props) => {
  const cleanProps = { ...props };
  delete cleanProps.icon; // or <TextField will complain on spurious "icon" prop
  delete cleanProps.tellChange; // or <TextField will complain on spurious "icon" prop

  const [
    field,
    // eslint-disable-next-line no-unused-vars
    meta, // need to specify something here, because useField() returns an array, and we pick only [0] and [2]
    helpers,
  ] = useField(props);

  const { tellChange } = props;

  // tap value changes and report
  const handleChange = (event) => {
    const newValue = event.target.value;
    helpers.setValue(newValue);
    if (tellChange) {
      tellChange(newValue);
    }
  };

  // add icon if defined
  if (props.icon) {
    cleanProps.InputProps = {
      endAdornment: props.icon && (
        <InputAdornment position="end">
          <Icon component={props.icon} className={props.iconclassname} />
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      id={props.id}
      // ref={props.innerref}
      {...field}
      {...cleanProps}
      autoComplete="off"
      onChange={handleChange}
      className={twMerge(
        "rounded border border-solid border-gray-300 bg-gray-200 outline-none focus:outline-offset-0 focus:outline-blue-300",
        props.className || "",
      )}
      // InputProps={{
      //   endAdornment: props.icon && (
      //     <InputAdornment position="end">
      //       <Icon component={props.icon} className={props.iconclassname} />
      //     </InputAdornment>
      //   ),
      // }}
    />
  );
};
export default StyledFormikField;
