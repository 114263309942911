import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getBaseUrl = () => {
  // eslint-disable-next-line no-undef
  return process.env.REACT_APP_ONBOARDING_SERVER;
};

const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: async (
      headers,
      // , { getState }
    ) => {
      try {
        if (window?.Clerk?.session) {
          const token = await window.Clerk.session.getToken();
          if (token) {
            headers.set("Authorization", `Bearer ${token}`);
          }
        }
      } catch (err) {
        //
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWorkflows: builder.query({
      query: (params) => `/api/workflow?hsContactId=${params.contactId}`,
      providesTags: ["workflow"],
    }),
    getWorkflowTemplates: builder.query({
      query: () => `/api/workflow/template`,
    }),
    assignWorkflowMethod: builder.mutation({
      query: (params) => ({
        url: `/api/session`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["workflow"],
    }),
  }),
});

export default onboardingApi;

export const {
  useGetWorkflowsQuery,
  useGetWorkflowTemplatesQuery,
  useAssignWorkflowMethodMutation,
} = onboardingApi;
