import ClipLoader from "react-spinners/ClipLoader";

import Backdrop from "./Backdrop";

const Spinner = (props) => {
  return (
    <>
      <Backdrop />
      <div className="pointer-events-none fixed z-[2] flex size-full flex-col items-center justify-center">
        <ClipLoader loading={true} color="white" />
        <div className="text-white">{props.text}</div>
      </div>
    </>
  );
};

export default Spinner;
