const FieldGroup = (props) => {
  return (
    <div className={"my-1 " + (props.className || "")}>
      {props.label && <div className="text-blue-400">{props.label}</div>}
      {props.children}
    </div>
  );
};

export default FieldGroup;
