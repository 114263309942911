import { cardInfoString } from "../util/stripe";
import { useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { VscDebugBreakpointUnsupported } from "react-icons/vsc";
import { twMerge } from "tailwind-merge";

import OverlayDialog from "./OverlayDialog";

const CardSelectItem = (props) => {
  const { card, needsUpdate } = props;

  const [deleteDialog, setDeleteDialog] = useState(false);

  const deleteCard = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setDeleteDialog((prev) => !prev);
  };

  const reallyDeleteCard = () => {
    setDeleteDialog(() => false);
    props.onRemove();
  };

  if (props.new) {
    return (
      <div key="addcard" onClick={() => props.setSource("new")}>
        <input
          type="radio"
          id={props.id}
          name={props.name}
          checked={props.checked}
          readOnly
        ></input>
        <label className="px-4" htmlFor={props.id}>
          Add card
        </label>
      </div>
    );
  }

  // normal card
  return (
    <div
      key={props.id}
      onClick={() => (needsUpdate ? null : props.setSource(props.id))}
      className="flex flex-row"
    >
      <div className="grow cursor-pointer">
        <input
          className={twMerge("cursor-pointer", needsUpdate && "bg-gray-500")}
          type="radio"
          id={props.id}
          name={props.name}
          checked={props.checked}
          readOnly
          disabled={needsUpdate}
        ></input>
        <label className="cursor-pointer px-4" htmlFor={props.id}>
          {cardInfoString(card)}
          {needsUpdate && (
            <p
              title={
                needsUpdate
                  ? "This card has been used in the past, but for security reasons the customer needs to update his payment info.\nClick Add Card and then Customer link."
                  : ""
              }
              className="flex text-red-500"
            >
              <VscDebugBreakpointUnsupported className="text-2xl"></VscDebugBreakpointUnsupported>
              Needs update by customer
            </p>
          )}
        </label>
      </div>
      {props.checked && props.onRemove && !needsUpdate && (
        <div className="z-[1] cursor-pointer text-red-500">
          <IoTrashOutline
            onClick={deleteCard}
            className="z-[1] cursor-pointer text-red-500"
          />
        </div>
      )}
      {deleteDialog && (
        <OverlayDialog
          title="Delete card"
          message={`Really delete ${cardInfoString(card)}?`}
          // icon={ImQuestion}
          onYes={reallyDeleteCard}
          onNo={() => setDeleteDialog(() => false)}
        />
      )}
    </div>
  );
};

export default CardSelectItem;
