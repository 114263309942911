import { SignIn } from "@clerk/clerk-react";

const SignInPage = () => (
  <div className="flex w-full">
    <div className="mx-auto mt-12">
      <SignIn
        appearance={{
          elements: {
            footerAction: { display: "none" },
          },
        }}
      />
    </div>
  </div>
);
export default SignInPage;
