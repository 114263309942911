import { FiCopy, FiMail } from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const validityInHours = (expiry) =>
  Math.round((expiry - new Date().getTime() / 1000) / 3600); // round up, most likely

const linkify = (url) => `<a href="${url}">${url}`;

const PortalLink = ({ link, onClose }) => {
  const { selectedEmail, deal } = useSelector((state) => state.cashier);

  const copyClip = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard", {
      autoClose: 5000,
    });
  };

  const plainpart = `Hi${
    deal?.full_name ? " " + deal.full_name : ""
  }, please follow this link to securely update your payment methods. The link is valid for ${validityInHours(
    link.firstVisitExpiry,
  )} hours, that is, until ${new Date(link.firstVisitExpiry * 1000)}.\n\n`;

  const mailbody = {
    html: `${plainpart}${linkify(link.url)}</a>\n\nbest,\n`,
    plain: `${plainpart}${link.url}\n\nbest,\n`,
  };

  return (
    <div className="flex">
      <input
        defaultValue={link.url}
        className="grow text-ellipsis bg-gray-100"
      />
      <FiCopy
        className="m-1 cursor-pointer"
        title="Copy to clipboard"
        onClick={() => copyClip(link.url)}
      />
      <ToastContainer position="bottom-center" />
      <a
        href={
          `mailto:${selectedEmail}?subject=` +
          encodeURIComponent(`Update your payment information`) +
          "&html-body=" +
          encodeURIComponent(mailbody.html) +
          "&body=" +
          encodeURIComponent(mailbody.plain)
        }
      >
        <FiMail className="m-1 cursor-pointer" title="Send link by mail" />
      </a>
      <div className="mx-2 cursor-pointer" onClick={onClose} title="Close">
        X
      </div>
    </div>
  );
};

export default PortalLink;
