import { MdOutlineArrowForwardIos } from "react-icons/md";
import { twMerge } from "tailwind-merge";

const Breadcrumb = (props) => {
  const onClickText = props.onClickText || null;
  const onClick = props.onClick || null;
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center py-1",
        props.className,
      )}
    >
      <MdOutlineArrowForwardIos
        onClick={onClick}
        className="cursor-pointer text-lg"
      />
      <span
        onClick={onClickText}
        className={twMerge(onClickText && "cursor-pointer")}
      >
        {props.text}
      </span>
    </div>
  );
};

export default Breadcrumb;
