import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";

import App from "./pages/App";
import { store } from "./store";
// import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./store/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// eslint-disable-next-line no-undef
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
if (!clerkPubKey) {
  throw new Error("Missing Publishable Key");
}

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={clerkPubKey}>
      <Provider store={store}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </Provider>
    </ClerkProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(console.log); // outputs performance objects like LCP, FID, TTFB, FCB to … in this case console
