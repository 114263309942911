import { twMerge } from "tailwind-merge";

const Box = (props) => {
  return (
    <div
      className={twMerge("flex flex-col", props.outerClassName)}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
    >
      <div
        className={twMerge(
          "mx-auto my-2 w-6/12 rounded-md border border-solid border-gray-300 bg-white p-8",
          props.className,
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Box;
