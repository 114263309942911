import {
  PS_DELETED,
  PS_FAILED,
  PS_PENDING,
  PS_SUCCESS,
  PS_UNSCHEDULED,
} from "../../store/constants";
import { VscDebugBreakpointUnsupported } from "react-icons/vsc";
import { twMerge } from "tailwind-merge";

const PaymentStatus = (props) => {
  const { payment } = props;

  let className = "";

  let explanation = payment.status_text;

  switch (payment.status) {
    case PS_UNSCHEDULED:
      explanation = "The card will have expired by the scheduled date";
      className = "text-red-500";
      break;
    case PS_DELETED:
      className = "text-red-500";
      break;
    case PS_FAILED:
      className = "text-red-500";
      break;
    case PS_SUCCESS:
      className = "text-green-500";
      break;
    case PS_PENDING:
      className = " text-amber-500";
      break;
    default:
  }

  return (
    <div className={twMerge("flex", className)}>
      {(payment.status_text || payment.status === "unscheduled") && (
        <VscDebugBreakpointUnsupported
          className="cursor-help text-2xl"
          title={explanation}
        />
      )}
      {payment.status}
    </div>
  );
};
export default PaymentStatus;
