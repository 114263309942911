import { useEffect } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

import Backdrop from "./Backdrop";

const OverlayDialog = (props) => {
  const { title, icon, message2, onNo, onYes, message, warningMessage, ok } =
    props;

  const handleKey = (event) => {
    // console.log("User keypress", event.key);
    switch (event?.key) {
      case "Enter":
        return onYes();
      case "Escape":
      case "Backspace":
        return onNo();
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(
    <>
      <Backdrop />
      <div className="fixed z-[2] flex size-full flex-col items-center justify-center">
        <div className="w-9/12 rounded bg-white p-2 shadow-lg lg:w-6/12 xl:w-4/12">
          <div className="my-2 w-full justify-between text-center font-bold">
            {title}
          </div>

          {icon && (
            <div
              className={twMerge(
                "flex justify-around text-9xl",
                ok ? "text-green-500" : "text-red-500",
              )}
            >
              {icon()}
            </div>
          )}
          <div className="w-full justify-between text-center">{message}</div>
          {warningMessage && (
            <div className="m-1 w-full justify-between rounded border border-dashed border-black bg-yellow-200 p-2 text-center">
              {warningMessage}
            </div>
          )}
          {message2 && (
            <div className="w-full justify-between text-center">{message2}</div>
          )}

          <div className="flex flex-row justify-between">
            <div
              onClick={onNo}
              className="m-4 cursor-pointer rounded border border-blue-400 p-2 shadow-md"
            >
              No
            </div>
            <div
              onClick={onYes}
              className="m-4 cursor-pointer rounded border border-blue-400 p-2 shadow-md"
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modaldiv"),
  );
};

export default OverlayDialog;
