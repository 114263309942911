// redux store https://redux-toolkit.js.org/rtk-query/overview
import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";

import adminReducer from "./admin";
import backendApi from "./api";
import appReducer from "./app";
import authReducer from "./auth";
import cashierReducer from "./cashier";
import onboardingApi from "./onboarding-api";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [backendApi.reducerPath]: backendApi.reducer,
    auth: authReducer,
    app: appReducer, // global state
    cashier: cashierReducer, // cashier mode things
    admin: adminReducer, // admin mode
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      backendApi.middleware,
      onboardingApi.middleware,
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
