import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";

import { useAttachPaymentMethodMutation } from "./api";
import { setUser } from "./app";
import autocompleteContext from "./contextAutoComplete";

// mastermind API
// eslint-disable-next-line no-undef
const mastermindApiEndpoint = process.env.REACT_APP_apiEndpoint; // must prefix env vars with REACT_APP_, see .env

const AuthContext = autocompleteContext; // with autocomplete

const hasRequiredRole = (metadata = {}) => {
  const REQ_APP = "charging";
  const REQ_ROLE = "view";
  const requiredRole = REQ_ROLE;
  const application = REQ_APP;

  try {
    if (!requiredRole) {
      return false;
    }
    const { roles = [] } = metadata;
    const gotRoles = roles[application];
    if (gotRoles.includes(requiredRole)) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const AuthContextProvider = (props) => {
  const [overlay, setOverlay] = useState("");

  const [clerktoken, setClerkToken] = useState("");

  const auth = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    auth.getToken().then((token) => {
      setClerkToken(() => token);
      const { public_metadata = {} } = decodeToken(token) || {};
      const { mappings = {} } = public_metadata;
      const { charging = {} } = mappings;
      const { userId } = charging;
      const repid = +userId;

      const access = hasRequiredRole(public_metadata);
      dispatch(setUser({ access, repid }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [attachPaymentMethod] = useAttachPaymentMethodMutation();
  const { stripeCustomerId } = useSelector((state) => state.cashier);

  const linkCfproductToHubspotProduct = async (cf, hs) => {
    try {
      console.log("link", cf, hs);
      const data = await fetch(`${mastermindApiEndpoint}/linkCfToHubspot`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${clerktoken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          cf,
          hs,
        }),
      });
      const list = await data.json();

      console.log("link result", list);
      return list;
    } catch (err) {
      return [];
    }
  };

  const searchHubspotProducts = async (fuzzyname) => {
    try {
      const data = await fetch(
        `${mastermindApiEndpoint}/getHubspotProductByName/${fuzzyname}`,
        {
          headers: {
            authorization: `Bearer ${clerktoken}`,
          },
        },
      );
      const list = await data.json();

      console.log("hs products by name", list);
      return list;
    } catch (err) {
      return [];
    }
  };

  const getClickFunnelsProduct = async (cfProductId) => {
    try {
      const data = await fetch(
        `${mastermindApiEndpoint}/getClickFunnelsProduct/${cfProductId}`,
        {
          headers: {
            authorization: `Bearer ${clerktoken}`,
          },
        },
      );
      const list = await data.json();

      console.log("cf products", list);
      return list;
    } catch (err) {
      return [];
    }
  };

  const addCardToCustomer = async (customerName, card, stripe) => {
    try {
      setOverlay(() => "Saving card to customer…");
      const createCardResult = await createPaymentMethod(
        stripe,
        card,
        customerName,
      );

      if (createCardResult.error) {
        throw new Error(
          JSON.stringify({
            decline_code: createCardResult.error.code,
            message: createCardResult.error.message,
          }),
        );
      }
      const added = createCardResult.paymentMethod.card;
      const addedCardResult = {
        id: createCardResult.paymentMethod.id,
        card: {
          brand: added.brand,
          last4: added.last4,
          exp_month: added.exp_month,
          exp_year: added.exp_year,
        },
      };

      // attach to customer
      const { data: attachedId, error: attachError } =
        await attachPaymentMethod({
          paymentMethodId: addedCardResult.id,
          customerId: stripeCustomerId,
        });

      if (attachError) {
        console.log("attacherr", attachError);
        throw new Error(JSON.stringify(attachError));
      }

      return attachedId;
    } catch (err) {
      // TODO re-catch?
      throw new Error(err);
    }
  };

  // this one call is not actually an api.mastermind call; it calls stripe directly.
  const createPaymentMethod = (stripe, card, name) => {
    return stripe.createPaymentMethod({
      type: "card",
      card: card, // yep, actually the HTML element
      billing_details: {
        name: name,
      },
    });
  };

  // rewrite to redux, and should use smarty directly, not through api endpoint
  const autocompleteAddress = async (params) => {
    const { search, selected: selectThis, country = "US" } = params;

    const url = `${mastermindApiEndpoint}/autocompleteaddress?search=${encodeURIComponent(
      search,
    )}&country=${country}${
      selectThis?.length ? "&selected=" + encodeURIComponent(selectThis) : ""
    }`;

    try {
      const autocomplete = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${clerktoken}`,
          "content-type": "application/json",
        },
      }).then((res) => res.json());

      return autocomplete;
    } catch (err) {
      console.log("autocomplete err", err);
      return [];
    }
  };

  const contextValue = {
    autocompleteAddress, // TODO go direct smarty
    addCardToCustomer,
    overlay,
    setOverlay,

    // TODO ADMIN stuff should be reduxified, clerkified
    getClickFunnelsProduct, // only for /admin page
    searchHubspotProducts, // only for /admin page
    linkCfproductToHubspotProduct, // only for /admin page
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
