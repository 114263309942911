import useDebouncedInput from "../hooks/useDebouncedInput";
import {
  useGetStripeCustomerQuery,
  useGetSubscriptionQuery,
} from "../store/api";
import { selectEmail } from "../store/cashier";
import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

import StyledFormikField from "./StyledFormikField";

// eslint-disable-next-line no-undef
const devMode = process.env.REACT_APP_devMode;

const SubscriptionLookup = (props) => {
  const { onClear, pickDeal } = props;

  const [subId, debounceSubId] = useDebouncedInput();
  const [searching, setSearching] = useState(false);

  const dispatch = useDispatch();
  // watch debounced subid
  const { data: sub, error: subLookupError } = useGetSubscriptionQuery(
    { id: subId },
    { skip: !subId.trim() },
  );

  const handleChange = (newSubId) => {
    setSearching(() => true);
    debounceSubId(newSubId);
  };

  const { selectedEmail } = useSelector((state) => state.cashier);

  const [customerId, setCustomerId] = useState(null);
  const { data: stripeCustomer } = useGetStripeCustomerQuery(
    { id: customerId },
    { skip: !customerId },
  );

  useEffect(() => {
    if (stripeCustomer) {
      debounceSubId("", true);
      onClear();
      if (stripeCustomer?.email && selectedEmail !== stripeCustomer.email) {
        console.log(
          `setting email ${stripeCustomer.email}from stripe customer`,
        );
        dispatch(selectEmail(stripeCustomer.email));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeCustomer]);

  useEffect(() => {
    setSearching(() => false);
    // if (subLookupError) {
    // }
    if (sub) {
      if (sub.metadata?.deal_id) {
        console.log(
          "we can set deal directly. need customer though",
          sub.metadata.deal_id,
        );
        pickDeal(sub.metadata.deal_id);
        // set cashier deal. cashier should figure customer from it
        return;
      }
      console.log("we can set customer", sub.customer);

      if (sub.customer) {
        setCustomerId(() => sub.customer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subLookupError, sub]);

  return (
    <div className="mt-4">
      <label htmlFor="deal" className="mt-4 text-blue-400">
        by Stripe subscription ID
        {devMode && " (try sub_1MzQ2ELYbD2uWeLilEfzFSzm)"}
      </label>
      <div>
        <StyledFormikField
          type="text"
          className={twMerge("w-full p-2")}
          name="sub"
          tellChange={handleChange}
          iconclassname={searching ? " animate-ping" : ""}
          icon={searching ? MdSearch : null}
        />
        {subLookupError && (
          <div>{`Could not find subscription "${subId}"`}</div>
        )}
        {sub && (
          <ul>
            <i>{`Found ${sub.id}, looking up deal/email`}</i>
          </ul>
        )}
      </div>
    </div>
  );
};

export default SubscriptionLookup;
