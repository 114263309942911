import {
  useGetProductsQuery,
  useGetScheduledPaymentsQuery,
  useUpdatePaymentMutation,
} from "../store/api";
import { PS_DELETED, PS_SCHEDULED } from "../store/constants";
import { dt } from "../util/stripe";
import { isAfter, isBefore, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

import EditScheduledPayment from "./EditScheduledPayment";
import OverlayDialog from "./OverlayDialog";
import PaymentEntry from "./ScheduledPane/PaymentEntry";

const FILTER_DEAL = "Deal";
const FILTER_ALL = "All";

const groupArrayByField = (arr, fieldname, commonfields) => {
  const out = [];
  arr.forEach((entry) => {
    const key = entry[fieldname];
    if (!out[key]) {
      out[key] = { entries: [] }; // init
    }
    out[key].entries.push(entry);
    commonfields.forEach((field) => {
      out[key][field] = entry[field];
    });
  });
  return out;
};

const sortByRetryGroupAndEventDate = (inputpayments, dealFilter, dealId) => {
  const payments = possiblePayments(inputpayments);
  const referenceDates = payments
    .filter((e) => !e.retry_reference)
    .map((e) => ({ id: e.id, refDate: dt(e.payment_date) }));

  const settledpayments = [];
  let maxdates = []; // {id: n, groupLastDate: date}

  const decorated = payments
    // glean info on groups, references, max dates
    .map((payment) => {
      const paymentDate = dt(payment.payment_date);
      const ref =
        referenceDates.find((ref) => ref.id === payment.retry_reference) || {};

      const refid = payment.retry_reference ? ref.id : payment.id;

      if (payment.status === "success" && !settledpayments.includes(refid)) {
        settledpayments.push(refid);
      }
      const groupRefDate = payment.retry_reference ? ref.refDate : paymentDate;

      // check if this is max date of retry group
      const maxsofar = maxdates.find((maxdate) => maxdate.id === refid);
      if (!maxsofar) {
        const entry = { id: refid, maxDate: paymentDate };
        // console.log("add to maxdates", entry);
        maxdates.push(entry);
      } else {
        // did contain a date
        if (isBefore(maxsofar.maxDate, paymentDate)) {
          maxdates = maxdates.map((entry) => {
            if (entry.id === refid) {
              entry.maxDate = paymentDate;
            }
            return entry;
          });
        }
      }
      return {
        ...payment,
        // should sort by "ref date"
        groupRef: payment.retry_reference || payment.id, // sort should sort by referenced payments ID and date
        groupRefDate, // first date in this group
        groupRefId: refid,
      };
    })
    // actually decorate settled and maxDate
    .map((e) => {
      const settled = !!settledpayments.find((id) => id === e.groupRefId); // is any payment in our refid group settled? if so, we're good in this group

      const maxDateInGroup = maxdates.find(
        (entry) => entry.id === e.groupRefId,
      );

      return {
        ...e,
        settled,
        maxDate: maxDateInGroup.maxDate,
      };
    })
    // payment deal filter etc
    .filter((payment) => {
      if (dealFilter === FILTER_DEAL && +payment.deal_id !== +dealId) {
        return false;
      }
      return true;
    });

  const groupedByRefId = groupArrayByField(decorated, "groupRefId", [
    "maxDate",
    "groupRefId",
  ]);
  // console.log('grouped', groupedByRefId)
  const ungrouped = [];
  groupedByRefId
    .sort((a, b) => {
      if (a.maxDate.getTime() === b.maxDate.getTime()) {
        // can't === two objects (date)
        return a.groupRefId < b.groupRefId ? 1 : -1;
      }
      return a.maxDate > b.maxDate ? -1 : 1;
    })
    .forEach((group) => {
      ungrouped.push(
        ...group.entries
          .sort((a, b) => {
            // sort entries within group
            return a.payment_date > b.payment_date ? -1 : 1;
          })
          .map((entry) => ({ ...entry, countEntries: group.entries.length })), // how many retries in group
      );
    });
  // console.log("ungrouped", ungrouped);

  return ungrouped;
};

const FilterButton = ({
  label,
  selected,
  payments,
  onClick = null,
  first,
  last,
}) => (
  <div
    onClick={onClick}
    className={twMerge(
      "cursor-pointer border p-1",
      selected && "bg-blue-500 text-white",
      first && "rounded-bl rounded-tl",
      last && "rounded-br rounded-tr",
    )}
  >
    {label} ({payments})
  </div>
);

const FilterButtons = (props) => {
  const { payments = [], onChangeFilter = null } = props;
  const { selectedDeal } = useSelector((state) => state.cashier);

  const [selected, setSelected] = useState(0);

  const dealPayments = payments.filter(
    (payment) => +payment.deal_id === +selectedDeal,
  );

  const buttons = [
    {
      label: FILTER_DEAL,
      payments: dealPayments,
    },
    {
      label: FILTER_ALL,
      payments: payments,
    },
  ];

  const onSelect = (index) => {
    setSelected(() => index);
    if (onChangeFilter) {
      onChangeFilter(buttons[index].label);
    }
  };

  return (
    <div className="mb-2 flex items-center">
      <div className="grow text-blue-500">Scheduled Payments</div>
      {buttons.map(({ label, payments }, index) => (
        <FilterButton
          key={label}
          selected={index === selected}
          first={index === 0}
          last={index === buttons.length - 1}
          payments={payments.length}
          label={label}
          onClick={() => onSelect(index)}
        />
      ))}
    </div>
  );
};

const possiblePayments = (payments = []) =>
  payments.filter((payment) => {
    if (payment.status === PS_DELETED) {
      return false;
    }
    return true;
  });

const productName = (productId, historicalName, allProducts = []) => {
  if (!allProducts?.length) {
    return historicalName;
  }
  const found = allProducts.find((prod) => +prod.id === +productId);
  if (!found) {
    return historicalName;
  }
  return found.name; // new one
};

const ScheduledPaymentsPane = () => {
  const { selectedDeal, deal } = useSelector((state) => state.cashier);

  const { data: { stripePrices: allProducts } = {} } = useGetProductsQuery();

  const [updatePayment] = useUpdatePaymentMutation();

  const { stripeCustomerId } = useSelector((state) => state.cashier);
  const { data: paymentsQuery } = useGetScheduledPaymentsQuery(
    { customerId: stripeCustomerId },
    { skip: !stripeCustomerId },
  );
  // console.log("got new payments", paymentsQuery);

  const [dealFilter, setDealFilter] = useState("Deal"); // 'Deal' | 'All', see FilterButtons

  const filterScheduledPayments = (newFilter) => setDealFilter(() => newFilter);

  // deprecate/fix
  const [sortedScheduledPayments, setSortedScheduledPayments] = useState([]);

  useEffect(() => {
    setSortedScheduledPayments(() =>
      sortByRetryGroupAndEventDate(paymentsQuery, dealFilter, selectedDeal),
    );
  }, [paymentsQuery, dealFilter, selectedDeal]);

  const [editScheduledPayment, setEditScheduledPayment] = useState(null);

  const editPayment = (payment) => {
    setEditScheduledPayment(() => payment);
  };

  const toggleScheduledEntry = ({ id, newScheduledState }) => {
    setPauseDialog(() => false);

    updatePayment({
      id,
      status: newScheduledState ? PS_SCHEDULED : PS_DELETED,
    });
  };

  const retrySchedule = (payload, id) => {
    // mangling payload is safe here, cause it is a copy

    payload.retry = true;

    if (!payload.retry_reference) {
      payload.retry_reference = id; // refer to the ORIGINALLY failed payment, don't refer to a retry
    }
    // console.log("retry this one", payload);

    delete payload.copy;
    setEditScheduledPayment(() => payload);
  };

  const copySchedule = (payload) => {
    // mangling payload is safe here, cause it is a copy

    // delete more stuff here
    delete payload.retry;
    delete payload.retry_reference;

    payload.copy = true;
    setEditScheduledPayment(() => payload);
  };

  const copyScheduledPayment = (payload) => {
    const copy = { ...payload };

    // clean up before
    delete copy.id;
    delete copy.idempotency_key;
    delete copy.status;
    delete copy.status_text;

    if (
      payload.settled ||
      ((payload.status === "scheduled" || payload.status === "unscheduled") &&
        isAfter(parseISO(payload.payment_date), new Date()))
    ) {
      // settled, or (not tried yet and in the future)
      return copySchedule(copy, payload.id);
    }
    return retrySchedule(copy, payload.id);
  };

  const [pauseDialog, setPauseDialog] = useState(null); // can be {id: null, newScheduledState: false}

  return (
    <>
      <FilterButtons
        payments={possiblePayments(paymentsQuery)}
        deal={deal}
        onChangeFilter={filterScheduledPayments}
      />
      {!possiblePayments(paymentsQuery).length ||
      !sortedScheduledPayments.length ? (
        <div>No scheduled payments found.</div>
      ) : (
        sortedScheduledPayments.map((payment) => (
          <PaymentEntry
            id={payment.id}
            key={payment.id}
            onCopy={copyScheduledPayment}
            productName={productName(
              payment.product_id,
              payment.product_name,
              allProducts,
            )}
            payment={payment}
            editPayment={editPayment}
            setPauseDialog={setPauseDialog}
          />
        ))
      )}

      {editScheduledPayment && (
        <EditScheduledPayment
          payment={editScheduledPayment}
          onClose={() => setEditScheduledPayment(() => null)}
        />
      )}
      {pauseDialog && (
        <OverlayDialog
          title={
            pauseDialog.newScheduledState === false
              ? "Delete payment"
              : "Resume payment"
          }
          message={
            pauseDialog.newScheduledState === false
              ? "Really delete this payment?"
              : // ? "Really stop this payment? You can resume it later."
                "Really resume this payment?"
          }
          onYes={() => toggleScheduledEntry(pauseDialog)}
          onNo={() => setPauseDialog(() => null)}
        />
      )}
    </>
  );
};

export default ScheduledPaymentsPane;
