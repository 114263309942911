import {
  SEGMENT_PAYMENT,
  SEGMENT_PAYMENTPLAN,
  // SEGMENT_SUBSCRIPTION,
} from "../store/constants";
import { twMerge } from "tailwind-merge";

const SegmentButton = (props) => {
  return (
    <div
      className={twMerge(
        "my-4 flex-1 cursor-pointer border border-solid border-gray-100 py-2 text-center",
        props.className,
        props.segment === props.value
          ? "border-blue-300 bg-blue-100"
          : "bg-gray-100",
        props.first && "rounded-bl rounded-tl",
        props.last && "rounded-br rounded-tr",
      )}
      onClick={props.setSegment ? () => props.setSegment(props.value) : null}
    >
      {props.children}
    </div>
  );
};

const SegmentButtons = (props) => {
  const segments = [
    {
      value: SEGMENT_PAYMENT,
      label: "Payment",
    },
    // {
    //   value: SEGMENT_SUBSCRIPTION,
    //   label: "Subscription",
    // },
    {
      value: SEGMENT_PAYMENTPLAN,
      label: "Payment plan",
    },
  ];

  const { selectedSegment, chooseSegment } = props;
  return (
    <div className="flex w-full flex-row">
      {segments.map((seg, index) => (
        <SegmentButton
          key={index}
          first={index === 0}
          last={index === segments.length - 1}
          value={seg.value}
          segment={selectedSegment}
          setSegment={chooseSegment}
        >
          {seg.label}
        </SegmentButton>
      ))}
    </div>
  );
};

export default SegmentButtons;
