import { createSlice } from "@reduxjs/toolkit";

const appReducer = createSlice({
  name: "app",
  initialState: {
    user: {
      access: false,
      name: "",
      repid: null,
    },
  },
  reducers: {
    setAppMode: (state, action) => {
      const { payload } = action;
      state.mode1 = payload;
    },
    setUser: (state, action) => {
      const { payload } = action;
      const user = { ...state.user };
      state.user = { ...user, ...payload };
    },
  },
});

export const { setAppMode, setUser } = appReducer.actions;
export default appReducer.reducer;
