import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon as SelectorIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";

import FieldGroup from "./FieldGroup";

const ProductDropdown = (props) => {
  const { selected, onChange, products, grouplabel } = props;
  return (
    <>
      {/* Products / ProductSets */}
      <FieldGroup label={grouplabel}>
        <Listbox value={selected} onChange={onChange} id="prodsel">
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span
                    className={`block truncate ${!selected?.template?.label ? "text-gray-400" : ""}`}
                  >
                    {selected?.template?.label || "Select an option"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon
                      className="size-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {products.map((product) => {
                      return (
                        <Listbox.Option
                          key={product.id}
                          className={({ active }) =>
                            twMerge(
                              active
                                ? "bg-indigo-600 text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9",
                            )
                          }
                          value={product}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={twMerge(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate",
                                )}
                              >
                                {product.template.label}
                              </span>

                              {selected ? (
                                <span
                                  className={twMerge(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                  )}
                                >
                                  <CheckIcon
                                    className="size-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </FieldGroup>
    </>
  );
};

export default ProductDropdown;
