import { getAddressOnly } from "../util/smarty";
import { formatAddress } from "../util/stripe";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AddressLookupDialog from "./AddressLookupDialog";
import DealTaxPreview from "./DealTaxPreview";

const AddressSelector = (props) => {
  const { chargeText, chargeAmount, product, tellTax, creditAmount } = props;

  const { deal } = useSelector((state) => state.cashier);

  const [address, setAddress] = useState(getAddressOnly(deal));
  useEffect(() => {
    const adr = getAddressOnly(deal);
    setAddress(() => adr);
  }, [deal]);

  const [showDialog, setShowDialog] = useState(false);

  const handleAddressClick = () => {
    setShowDialog(() => true);
  };

  const closeAddressSelectorModal = () => {
    setShowDialog(() => false);
  };

  return (
    <>
      <div className="mb-2 mt-4 text-blue-400">
        Address for shipping/tax purposes
      </div>

      <div className="flex">
        <div
          className="grow pt-2 text-justify text-sm text-gray-400"
          onClick={handleAddressClick}
        >
          {deal.address ? formatAddress(deal) : "(Click to set)"}
        </div>
      </div>

      {showDialog && (
        <AddressLookupDialog
          address={getAddressOnly(deal)}
          onClose={closeAddressSelectorModal}
        />
      )}

      <DealTaxPreview
        address={address}
        chargeText={chargeText}
        chargeAmount={chargeAmount}
        creditAmount={creditAmount}
        product={product}
        tellTax={tellTax}
      />
    </>
  );
};
export default AddressSelector;
